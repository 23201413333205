import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Layout from '@/components/Layout';
import Header from '@/components/common/Header';

function initializeHubspotForm() {
  if ('hbspt' in window) {
    window.hbspt.forms.create({
      portalId: '25137542',
      formId: '0fdfe84f-0cfc-425d-80e2-1c92ded3d010',
      region: 'eu1',
      target: '#form-container',
    });
  } else {
    setTimeout(initializeHubspotForm, 500);
  }
}

const Contact = () => {
  useEffect(() => {
    initializeHubspotForm();
  }, []);
  return (
    <Layout className="layout__background">
      <Helmet>
        <script
          charSet="utf-8"
          type="text/javascript"
          src="//js-eu1.hsforms.net/forms/shell.js"
        />
      </Helmet>
      <Header />
      <div className="contact-form-wrapper">
        <h1>Let&#39;s talk</h1>
        <div id="form-container" className="contact-form" />
      </div>
    </Layout>
  );
};

export default Contact;
